import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from "gatsby-image"
import {Image} from '../utils'

export const Content = () => 
  <div>
    <h1>Session 2 (Feb 25<sup>th</sup>)</h1>

    <h2>Homework 1</h2>
    <audio src="https://lyceum.aularon.com/audio/01.m4a" controls />
    <Image src="img010" />
    <Image src="img011" />

    <h2>Homework 2</h2>
    <audio src="https://lyceum.aularon.com/audio/05.m4a" controls />
    <Image src="img033" />
    <Link to="/">Go back to the homepage</Link>
  </div>


const SecondPage = () => (
  <Layout>
    <SEO title="Session 2" />
    <Content />
  </Layout>
)



export default SecondPage
